import { useEffect, useRef, useState } from 'react';
import {
	Autocomplete,
	CircularProgress,
	Container,
	Divider,
	TextField,
	Typography
} from '@mui/material';
import { withUser } from "../../context/UserContext";
import { isEqual, isEmpty, map, sortBy, max, reduce, forEach, isNull } from 'lodash';
import moment from "moment";
import xAxisHelper from './xAxisHelper';

// import Chart from 'chart.js';
// import 'chartjs-plugin-annotation';

const DemoGraph = ({ user }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [labResultsLoading, setLabResultsLoading] = useState(false);
  const [HJ_token, setHJ_token] = useState('');
  const [hj_demographics, setHj_demographics] = useState([]);
  const [hj_activePatient, setHJ_ActivePatient] = useState({
		labResults: [],
		vitals: [],
		LabResultsBUNCREA: [],
		LabResultsLDL: [],
		LabResultsHDL: [],
		medicationResults: []
	});
  const [open, setOpen] = useState(false);

	const scriptRef = useRef(null);
	const chartRef = useRef(null);
	const testRef = useRef(null);
	const [showChart, setShowChart] = useState(false);

	const getLast24MonthsFormatted = () => {
		const today = moment();
		const result = [];
		for (let i = 0; i < 24; i++) {
			const lastMonth = moment(today).subtract(i, 'months');
			result.push(lastMonth.format('YYYY-MM'));
		}
		return result;
	};

	const xAxisLabels = map(getLast24MonthsFormatted(), (date) =>
		moment(date).format('MM/YY')
	).reverse();

	useEffect(() => {
		if (!labResultsLoading && !isEmpty(hj_activePatient.labResults)) {
			if (scriptRef.current) {
				const ctx = chartRef.current.getContext('2d');

				// if the chart is not undefined (e.g. it has been created)
				// then destory the old one so we can create a new one later
				if (testRef.current) {
					testRef.current.destroy();
				}

				const algorithm24Monthsback = () => {
					const last24MonthsFormatted = getLast24MonthsFormatted();
					const result = reduce(last24MonthsFormatted, (results, month) => {
						let temp = null;
						forEach(hj_activePatient.labResults, (patient) => {
							if (isEqual(month, moment(patient.date).format('YYYY-MM'))) {
								temp = patient;
							}
						})
						results.push(temp);
						return results
					}, []).reverse()
					return result;
				}
	
				testRef.current = new window.Chart(ctx, {
					type: 'line',
					data: {
						// labels: map(hj_activePatient, 'date'),
						// labels: [24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
						labels: xAxisLabels,
						datasets: [
							{
								label: 'Diabetes Data',
								// data: map(map(hj_activePatient, 'value'), Number),
								data: map(algorithm24Monthsback(), (element) => {
									return element ? element.value : null;
								}),
								backgroundColor: '#8884d8',
								borderColor: '#8884d8',
								tension: 0.1,
								fill: false,
							},
						],
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						spanGaps: true,
						legend: {
							display: false
						},
						tooltips: {
							callbacks: {
								title: function(tooltipItem, data) {
									return algorithm24Monthsback()[tooltipItem[0].index].date;
								}
							}
						},
						scales: {
							yAxes: [
								{
									ticks: {
										min: 4,
										stepSize: 1,
									},
									gridLines: {
										display: true,
									},
									scaleLabel: {
										display: true,
										labelString: 'A1C'
									}
								},
							],
							xAxes: xAxisHelper(hj_activePatient)
						},
						annotation: {
							annotations: [
								{
									type: 'box',
									yScaleID: 'y-axis-0',
									yMin: 0,
									yMax: 7,
									borderColor: 'rgba(0, 204, 0, 0.25)',
									borderWidth: 0,
									backgroundColor: 'rgba(0, 204, 0, 0.25)',
								},
								{
									type: 'box',
									yScaleID: 'y-axis-0',
									yMin: 7,
									yMax: 9,
									borderColor: 'rgba(255, 255, 0, 0.25)',
									borderWidth: 0,
									backgroundColor: 'rgba(255, 255, 0, 0.25)',
								},
								{
									type: 'box',
									yScaleID: 'y-axis-0',
									yMin: 9,
									yMax: Math.ceil(max(map(map(hj_activePatient.labResults, 'value'), Number)) + 1),
									borderColor: 'rgba(255, 51, 51, 0.25)',
									borderWidth: 0,
									backgroundColor: 'rgba(255, 51, 51, 0.25)',
								},
							],
						},
					},
				});
			}
		}
  }, [hj_activePatient]);

  useEffect(() => {
    hl_token()

		const script1 = document.createElement('script');
    script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.3/Chart.min.js';
    script1.async = true;
    document.body.appendChild(script1);

		let script2 = null;
		script1.onload = () => {
			script2 = document.createElement('script');
			script2.src = 'https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-annotation/0.5.7/chartjs-plugin-annotation.js';
			script2.async = true;
			document.body.appendChild(script2);

			scriptRef.current = script2;
		}

		return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, [])

  /**
   * 
   * Went with this approach due to having issue retriving HL_token cookie on the backend in java
   * server/controller/HJDemographicController.java file
   * With this approach I retrive the HL_token and sppend it to the header manually
   */
  const hl_token = async () => {
    setDataLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.accessToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    return fetch("/server/rest/auth/set-HLtoken", requestOptions)
      .then(response => response.text())
      .then(result => {
        setHJ_token(result);
        getAllClients(result);
      })
      .catch(error => console.log('error', error));
  }

  const getAllClients = async (hl_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.accessToken}`);
    myHeaders.append("Cookie", `HJ_token=${hl_token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch("/server/rest/get-clients", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setHj_demographics(result.query_result.data.rows);
        setDataLoading(false);
      })
      .catch(error => console.log('error', error));
  }

  const getLabResults = async (patientId) => {
    setLabResultsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.accessToken}`);
    myHeaders.append("Cookie", `HJ_token=${HJ_token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`/server/rest/get-lab-results?patientId=${patientId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
				console.log('result-->>', result)
				const modifiedDataTest = (dataList, date) => {
					return sortBy(map(dataList, (element) => {
						const newElement = element;
						newElement[date] = moment(element[date]).format('YYYY-MM-DD')
						return newElement;
					}), [date]);
				}

				const algorithm24Monthsback = (dataList, date) => {
					const last24MonthsFormatted = getLast24MonthsFormatted();
					const result = reduce(last24MonthsFormatted, (results, month) => {
						let temp = null;
						forEach(modifiedDataTest(dataList, date), (patient) => {
							if (isEqual(month, moment(patient[date]).format('YYYY-MM'))) {
								temp = patient;
							}
						})
						results.push(temp);
						return results
					}, [])
					return result;
				}
		
		const medicationResults = reduce(result.medicationResults, (results, medication) => {
			if (!results.hasOwnProperty(medication.name)) results[medication.name] = []
			results[medication.name].push(medication);
			return results
		}, {})

		setHJ_ActivePatient({
					labResults: modifiedDataTest(result.labResults.data, 'date'),
					vitals: algorithm24Monthsback(result.vitals.data, 'service_date'),
					LabResultsBUNCREA: algorithm24Monthsback(result.BUNCREAResults.data, 'date'),
					LabResultsLDL: algorithm24Monthsback(result.LDLResults.data, 'date'),
					LabResultsHDL: algorithm24Monthsback(result.HDLResults.data, 'date'),
					medicationResults: reduce(medicationResults, (results, value, key) => {
						results[key] = algorithm24Monthsback(value, 'hj_create_timestamp');
						return results;
					}, {}),
				});
        setLabResultsLoading(false);
				setShowChart(!isEmpty(modifiedDataTest(result.labResults.data, 'date')));
      })
      .catch((error) => {
				console.log('error', error)
			});
  }

  const selectPatientHandler = (e, data) => {
		setShowChart(false);
		setHJ_ActivePatient({
			labResults: [],
			vitals: [],
			LabResultsBUNCREA: [],
			LabResultsLDL: [],
			LabResultsHDL: [],
			medicationResults: []
		})
		if (!isNull(data)) getLabResults(data.client_patient_id)
  }


  return (
    <div className="card pcmh-profile-card" style={{ height: "95vh" }}>
      <div className="card-header pcmh-profile-card-header">
        Patient Data
      </div>
      <br />
      <Container style={{ height: '100%', overflow: 'auto' }}>
        <Autocomplete
          fullWidth
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={hj_demographics}
          loading={dataLoading}
          isOptionEqualToValue={
            (option, value) =>
              isEqual(`${option.first_name} ${option.last_name}`, `${value.first_name} ${value.last_name}`)
          }
          getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
          onChange={selectPatientHandler}
          // onInputChange={testHandler}
          renderOption={(props, option) => (
              <li {...props} key={option.client_patient_id}>
                {`${option.first_name} ${option.last_name}`}
              </li>
            )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={dataLoading ? 'Loading Patients' : 'Select Patient'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dataLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                  </>
                )}} />
          )} />
        <br />
        <Divider light />

        {labResultsLoading && (
          <div className="text-center my-5">
            <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                Loading...
            </button>
          </div>
        )}

          <div style={{ height: '1200px', display: showChart && scriptRef.current ? 'block' : 'none' }}>
						<canvas ref={chartRef} />
          </div>

					{!dataLoading && !showChart && !labResultsLoading && (
						<div
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Typography variant="h6">
								No Data
							</Typography>
						</div>
					)}
      </Container>
    </div>
  )
}

export default withUser(DemoGraph);
