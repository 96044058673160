import React, {useEffect, useState} from 'react'
import {withUser} from "../../../../../context/UserContext";
import {Rest} from "../../../../../util/rest";
import {toast} from "react-toastify";
import moment from "moment";
import TodayDataElementHeader from "../../../huddle/today/TodayDataElementHeader";
import {tConvert} from "../../../../../util/format";
import AppointmentPatient from "../../../huddle/today/AppointmentPatient";
import PatientItem from "../../../huddle/today/PatientItem";
import PatientInfo from "./PatientInfo";

const columns= [
    {
        "id": 1,
        "name": "Name",
        "dataType": "TEXT",
        "source": "EHR",
        "position": 0,
        "dataSourceMapping": "name",
        "array": false
    },
    {
        "id": 2,
        "name": "Provider",
        "dataType": "TEXT",
        "source": "EHR",
        "position": 1,
        "dataSourceMapping": "rsn",
        "array": false
    },
    {
        "id": 3,
        "name": "Last seen",
        "dataType": "TEXT",
        "source": "EHR",
        "position": 2,
        "dataSourceMapping": "bpm",
        "array": false
    },
    {
        "id": 4,
        "name": "Appt Status",
        "dataType": "TEXT",
        "source": "EHR",
        "position": 3,
        "dataSourceMapping": "labs",
        "array": true
    },
    {
        "id": 5,
        "name": "Visit Type",
        "dataType": "CHECK",
        "source": "EHR",
        "position": 4,
        "dataSourceMapping": "sameday",
        "array": false
    },
    {
        "id": 6,
        "name": "Reason of Visit",
        "dataType": "TEXT",
        "source": "EHR",
        "position": 5,
        "dataSourceMapping": "specialneeds",
        "array": true
    },
    {
        "id": 7,
        "name": "PCP",
        "dataType": "CHECK",
        "source": "EHR",
        "position": 6,
        "dataSourceMapping": "urgent",
        "array": false
    },
    {
        "id": 8,
        "name": "Care Gaps",
        "dataType": "CHECK",
        "source": "EHR",
        "position": 7,
        "dataSourceMapping": "preopclrnc",
        "array": false
    },
    {
        "id": 9,
        "name": "Open Referrals",
        "dataType": "CHECK",
        "source": "EHR",
        "position": 8,
        "dataSourceMapping": "outsiderecs",
        "array": false
    },
    {
        "id": 10,
        "name": "Huddle Notes",
        "dataType": "CHECK",
        "source": "PCMH",
        "position": 9,
        "dataSourceMapping": "socialworker",
        "array": false
    }
]
function PatientResults(props){
    const [dataElements, setDataElements] = useState([]);
    const [records, setRecords] = useState([])
    const [records2, setRecords2] = useState([])
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        loadDataElements();
        
        // loadRecords();
    }, []);

    useEffect(() => {
        loadAppointments(props.date);
    }, [props.date]);
    
    useEffect(() => {
        console.log(records2)
    }, [records2]);
    function loadAppointments(date){
        setLoading(true);
        let path = "/rest/ehr/appointments/"+date.format("YYYYMMDD");
        Rest.authFetch(props.user, path)
                .then(response => {
                          if(response.status === 401 || response.status === 403)
                              toast.error("Unauthorized!")
                          else if(response.status === 500)
                              toast.error("Error loading templates");
                          else {
                              setRecords2(response);
                              setLoading(false);
                          }
                      }
                )
                .catch(err => {
                    toast.error("Error loading appointments");
                    console.log(err);
                    setLoading(false);
                });
        
    }
    function loadDataElements() {
       setDataElements(columns);
        // let path = "/rest/huddle/dataelements";
        // Rest.authFetch(props.user, path)
        //         .then(response => {
        //                   if(response.status === 401 || response.status === 403)
        //                       toast.error("Unauthorized!")
        //                   else if(response.status === 500)
        //                       toast.error("Error loading templates");
        //                   else {
        //                       setDataElements(response);
        //                   }
        //               }
        //         )
        //         .catch(err => {
        //             toast.error("Error loading templates");
        //             console.log(err);
        //         });
    }

    function loadRecords() {

        if(props.listDate) {
            const searchDate = moment(props.listDate).format("yyyyMMDD");
            const path = "/rest/huddle/appointments/" + searchDate;
            Rest.authFetch(props.user, path).then(response => {
                if(response.status === 401 || response.status === 403)
                    toast.error("Unauthorized!")
                else if(response.status === 500)
                    toast.error("Error loading patient records");
                else {
                    setRecords(response);
                }
            })
                    .catch(err => {
                        toast.error("Error loading patient records");
                        console.log(err);
                    });
        }
    }


    return (
            <div className="pcmh-today-container">
                {loading &&
                 <div className="text-center my-5">
                     <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                         <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                         Loading...
                     </button>
                 </div>}

                <table>
                    <thead>
                    <tr>
                        {dataElements && dataElements.length > 0 &&
                         dataElements.sort((e1, e2) => e1.position - e2.position)
                                 .map((element) =>
                                              <th>{element.name}</th>)
                        }
                        {/*{dataElements && dataElements.length > 0 &&*/}
                        {/* dataElements.sort((e1, e2) => e1.position - e2.position)*/}
                        {/*         .map((element) =>*/}
                        {/*                      <TodayDataElementHeader*/}
                        {/*                              key={element.id}*/}
                        {/*                              header={element.name}/>)*/}
                        {/*}*/}
                    </tr>
                    </thead>
                    <tbody>

                    {records2 && records2.length > 0 &&
                     records2.map((appointment, index) =>
                                         <tr key={index}>
                                             <th>
                                                 {/*<span className="time">{tConvert(appointment.time)}</span>*/}
                                                 <PatientInfo
                                                         appointment={appointment}/>
                                                 {console.log("Appointment",appointment)}
                                             </th>
                                             <td>
                                                 {appointment.resource}
                                             </td>
                                             <td></td>
                                             <td>{appointment.status}</td>
                                             <td>{appointment.type}</td>
                                             <td>{appointment.reason}</td>
                                             <td>{appointment.pcp?(appointment.pcp.firstName+" "+appointment.pcp.lastName+", "+appointment.pcp.degree):""}</td>
                                             <td></td>
                                             <td></td>
                                             <td>notes</td>
                                             {/*{*/}
                                             {/*        dataElements && dataElements.length > 0 &&*/}
                                             {/*        dataElements.sort((e1, e2) => e1.position - e2.position)*/}
                                             {/*                .map((itemHeader, index) =>*/}
                                             {/*                             <PatientItem key={index}*/}
                                             {/*                                          appointment={appointment}*/}
                                             {/*                                          column={itemHeader}/>*/}
                                             {/*                )}*/}
                                         </tr>
                     )}

                    </tbody>

                </table>

            </div>
    )
}
export default withUser(PatientResults);