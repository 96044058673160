import React, {useEffect, useRef, useState} from 'react';
import {isEqual} from 'lodash';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import './AssessmentAdmin.css'
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {CloseCircleOutlined, CopyOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from 'uuid';
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import TextField from "@mui/material/TextField";
import moment from "moment";

import MailTemplateEditor from "../texteditor/MailTemplateEditor";
import MenuItem from "@mui/material/MenuItem";
import {Autocomplete} from "@mui/material";
import {createFilterOptions} from "@mui/material/Autocomplete";


const inputLabelProps = {
    formlabelclasses: {
        root: 'textField',
        focused: 'focused'
    },
};

// const opts = [{batchName:"Test"},{batchName:"Prueba"}];

function AssessmentBatchAssign(props) {
    const [open, setOpen] = useState(props.open);
    const [anonymous, setAnonymous] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [files, setFiles] = useState(null);
    const [firstLine, setFirstLine] = useState(true);
    const [toBeDeleted, setToBeDeleted] = useState(null);
    const [shouldAssign, setShouldAssign] = useState(false);
    const [template, setTemplate] = useState(props.template);
    const [urlKey, setUrlKey] = useState(uuidv4);
    const [effectiveDate, setEffectiveDate] = useState(moment().format('YYYY-MM-DDTHH:MM'));
    const [expirationDate, setExpirationDate] = useState(moment().add(1, 'M').format('YYYY-MM-DDTHH:MM'));
    const inputRef = useRef(null)
    const [shouldShowMailEditor, setShouldShowMailEditor] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectValues, setSelectValues] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [mailTemplate, setMailTemplate] = useState(null);
    const [shouldSaveTemplate, setShouldSaveTemplate] = useState(false);
    const [subject, setSubject] = useState(null);
    const [batchName, setBatchName] = useState(null);
    const [batchNames, setBatchNames] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({
                                                       recipient: false,
                                                       batchName: false,
                                                       subject: false,
                                                       template: false,
                                                       effectiveDate: false,
                                                       expirationDate: false
                                                   });
    const [assessmentsUrl, setAssessmentsUrl] = useState({Url: ""});
    const filter = createFilterOptions();

    useEffect(() => {
        setFieldErrors({
                           batchName: false,
                           subject: false,
                           template: false,
                           effectiveDate: false,
                           expirationDate: false
                       });
    }, [])

    useEffect(() => {
        if(open !== props.open && props.open) {
            setUrlKey(uuidv4);
            getAssessmentsUrl();
            populateDropDowns();
        }
        setOpen(props.open);
        setTemplate(props.template);

    }, [props.open, props.template]);

    useEffect(() => {     //Used to parse file 
        if(files && files.length > 0) {
            let r = [];
            setRecipients([]);
            for(let i = 0; i < files.length; i++) {
                const f = files.item(i);
                const reader = new FileReader();
                reader.onloadend = () => {
                    const content = reader.result;
                    const lines = content.match(/[^\r\n]+/g);
                    let fl = false;
                    lines.forEach((l) => {
                        if(!firstLine || fl) {
                            const parts = l.split(",");
                            r.push({name: parts[0], email: parts[1], urlKey: uuidv4()});
                        }
                        fl = true;
                    })
                    setRecipients(r.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
                }
                reader.readAsText(f);
            }
            setFieldErrors(fieldErrors => ({...fieldErrors, recipient: false}));
            // setRecipientError(false);
        }
    }, [files]);     //Used to parse file

    useEffect(() => {
        if(toBeDeleted) {
            setRecipients(recipients.filter(function (r) {
                return r.name !== toBeDeleted;
            }));
        }
    }, [toBeDeleted]);  // Used to delete already loaded recipients

    useEffect(() => {
        if(shouldAssign) {

            if(!validate()) {
                const batch = {
                    assignableId: props.template.id,
                    assignmentType: anonymous ? "ANONYMOUS" : "TARGETED",
                    dateCreated: new Date(),
                    dateEffective: effectiveDate,
                    dateExpires: expirationDate,
                    batchAssignmentMailTemplate: mailTemplate,
                    batchName: batchName,
                    subject: subject,
                    assignees: anonymous ? [{name: "Anonymous Assessment", urlKey: urlKey}] : recipients
                }
                Rest.authFetch(props.user, "/rest/assessments/batch", {
                    method: "PUT",
                    body: JSON.stringify(batch)
                })
                        .then(response => {
                            if(!response) {
                                toast.error("Error saving Template");
                            }
                        })
                        .then(() => {
                            setShouldAssign(false);
                            setRecipients([]);
                            clear()
                            props.onClose()
                        });
            }
        }
    }, [shouldAssign, open]);  // Creates assignment in the backend 

    useEffect(() => {
        if(!shouldShowMailEditor) {
            populateDropDowns();
        }
    }, [shouldShowMailEditor]);  // Loads mail templates

    useEffect(() => {
        const v = [{value: -1, label: "Create new template"}];
        if(templates && templates.length > 0) {
            templates.map((t) => v.push({value: t.id, label: t.name}));
        }
        setSelectValues(v);
    }, [templates]);  //Populates Template Drop Down

    useEffect(() => {
        if(selectedTemplateId) {

            if(selectedTemplateId < 0) {
                setMailTemplate({id: 0, active: true, name: "", template: ""});
                setShouldShowMailEditor(true);
            }
            else {
                Rest.authFetch(props.user, "/rest/mailtemplate/" + selectedTemplateId, {
                    accepts: 'application/json'
                })
                        .then(response => {
                            if(response && response.id > 0) {
                                setMailTemplate(response)
                            }
                            else {
                                toast.error("Error getting Template");
                            }
                        });
            }

        }
    }, [selectedTemplateId]);  //If the user selects 'New Template' will show editor, otherwise it loads template

    useEffect(() => {
        validate();
    }, [selectedTemplateId, batchName, subject]);

    function validate() {
        let errors = false;

        if(batchName === null || batchName.batchName === null) {//|| batchName.batchName.trim().length ===0) {
            setFieldErrors(fieldErrors => ({...fieldErrors, batchName: true}));
            errors = true;
        }
        else {
            setFieldErrors(fieldErrors => ({...fieldErrors, batchName: false}));
        }
        if(!anonymous && (subject === null || subject.trim().length === 0)) {
            setFieldErrors(fieldErrors => ({...fieldErrors, subject: true}));
            setShouldAssign(false);
            errors = true;
        }
        else {
            setFieldErrors(fieldErrors => ({...fieldErrors, subject: false}));
        }
        if(!anonymous && (recipients === null || recipients.length === 0)) {
            setFieldErrors(fieldErrors => ({...fieldErrors, recipient: true}));
            errors = true;
        }
        else {
            setFieldErrors(fieldErrors => ({...fieldErrors, recipient: false}));
        }
        if(!anonymous && (selectedTemplateId === null || selectedTemplateId <= 0)) {
            setFieldErrors(fieldErrors => ({...fieldErrors, template: true}));
            errors = true;
        }
        else {
            setFieldErrors(fieldErrors => ({...fieldErrors, template: false}));
        }
        return errors;
    }

    function processSubject(value) {
        setSubject(value);
        if(value !== null && value.trim().length > 0)
            setFieldErrors(fieldErrors => ({...fieldErrors, subject: false}));

    }

    function populateDropDowns() {
        Rest.authFetch(props.user, "/rest/mailtemplates?nameOnly=true&activeOnly=true")
                .then(response => {
                    if(response) {
                        setTemplates(response);
                    }
                });
        Rest.authFetch(props.user, "/rest/assignment/assessments/batches")
                .then(response => {
                    if(response) {
                        const batches = response.filter(b => b).map(batch => batch.batchName);
                        setBatchNames(batches);
                    }
                });
    }

    function getAssessmentsUrl() {
        Rest.authFetch(props.user, "/rest/assessments/url")
                .then(response => {
                    console.log("URL: " + response);
                    if(response) {
                        setAssessmentsUrl(response);
                    }
                });
    }

    function selectRecipientFile() {
        inputRef.current.click();
    }

    function clear() {
        setRecipients([]);
        setSubject(null);
        setSelectedTemplateId('');
        setBatchName(null);
    }

    const deleteEmailTemplateHandler = async () => {
        const {user} = props;
        const url = `/rest/mailtemplate/${mailTemplate.id}`;
        const data = {method: 'DELETE'}
        await Rest.authFetch(user, url, data).catch((err) => console.log(err));
        setShouldShowMailEditor(false);
    };

    return (


            <Dialog open={open}
                    className={shouldShowMailEditor ? "batch-assignment-dialog-full" : "batch-assignment-dialog"}>
                <DialogTitle classes={{root: "dialog-header"}}>Batch Assignment</DialogTitle>
                <DialogContent className="container-fluid d-flex h-100 flex-column">
                    {shouldShowMailEditor ?
                     <MailTemplateEditor template={mailTemplate} onClose={setShouldShowMailEditor}
                                         shouldSave={shouldSaveTemplate} onTemplateUpdate={setMailTemplate}
                                         onSave={setShouldSaveTemplate}/>
                                          :
                     <>
                         <>
                             <div className="row">
                                 <div className="col-6">
                                     <FormControlLabel
                                             classes={{root: 'FormControlLabel'}}
                                             label="Anonymous"
                                             control={
                                                 <Checkbox
                                                         classes={{root: "checkbox", checked: "checked"}}
                                                         checked={anonymous}
                                                         onChange={() => setAnonymous(!anonymous)}
                                                         // id={templateAnswer.id.toString()}
                                                 />
                                             }
                                     />
                                 </div>
                                 {!anonymous &&
                                  <div className="col-6">
                                      <TextField
                                              InputProps={inputLabelProps}
                                              label="Templates"
                                              variant="outlined"
                                              margin="dense"
                                              fullWidth
                                              select
                                              value={selectedTemplateId}
                                              onChange={(e) => setSelectedTemplateId(e.target.value)}
                                              error={fieldErrors.template}
                                      >
                                          {selectValues.map((option) => (
                                                  <MenuItem key={option.value} value={option.value}>
                                                      {option.label}
                                                  </MenuItem>
                                          ))}
                                      </TextField>
                                  </div>

                                 }
                             </div>
                             <div className="row">
                                 <div className="col">
                                     <Autocomplete
                                             value={batchName}
                                             onChange={(event, newValue) => {
                                                 // if (typeof newValue === 'string') {
                                                 //     setBatchName(newValue);
                                                 // }
                                                 // else 
                                                 if(newValue && newValue.inputValue) {
                                                     // Create a new value from the user input
                                                     setBatchName(newValue.inputValue);
                                                 }
                                                 else {
                                                     setBatchName(newValue);
                                                 }
                                             }}
                                             filterOptions={(options, params) => {
                                                 const filtered = filter(options, params);
                                                 const {inputValue} = params;
                                                 const isExisting = options.some((option) => inputValue === option);
                                                 // Suggest the creation of a new value
                                                 if(inputValue !== '' && !isExisting) {
                                                     // filtered.push({
                                                     //                   inputValue: params.inputValue,
                                                     //                   batchName: `Add "${params.inputValue}"`,
                                                     //               });
                                                     filtered.push(`Add "${inputValue}"`);
                                                 }
                                                 return filtered;
                                             }}
                                             selectOnFocus
                                             handleHomeEndKeys
                                             id="batch-name"
                                             options={batchNames}
                                             getOptionLabel={(option) => {
                                                 // Value selected with enter, right from the input
                                                 if(typeof option === 'string') {
                                                     if(option.substring(0, 5) === "Add \"" && option.endsWith("\""))
                                                         return option.substring(5, option.length - 1);
                                                     return option;
                                                 }
                                                 // Add "xxx" option created dynamically
                                                 if(option.inputValue) {
                                                     return option.inputValue;
                                                 }
                                                 // Regular option
                                                 return option.batchName;
                                             }}
                                             renderOption={(props, option) => <li {...props}>{option}</li>}
                                             freeSolo
                                             renderInput={(params) => (
                                                     <TextField {...params} margin="dense" label="Batch Name"
                                                                variant="outlined" error={fieldErrors.batchName}/>
                                             )}
                                     />
                                 </div>
                             </div>
                         </>
                         {!anonymous &&
                          <div className="row">
                              <div className="col">
                                  <TextField label="Subject"
                                             InputProps={inputLabelProps}
                                             variant="outlined"
                                             fullWidth
                                             margin="dense"
                                             required
                                             defaultValue={subject}
                                             onChange={(e) => processSubject(e.target.value)}
                                             error={fieldErrors.subject}
                                  />
                              </div>
                          </div>

                         }
                         <div className="row">
                             <div className="col-6">
                                 <TextField label="Effective Date"
                                            InputProps={inputLabelProps}
                                            type="datetime-local"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            required
                                            defaultValue={effectiveDate}
                                            onChange={(e) => setEffectiveDate(e.target.value)}
                                            error={fieldErrors.effectiveDate}
                                 />
                             </div>
                             <div className="col-6">
                                 <TextField label="Expiration Date"
                                            InputProps={inputLabelProps}
                                            type="datetime-local"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            required
                                            defaultValue={expirationDate}
                                            onChange={(e) => setExpirationDate(e.target.value)}
                                            error={fieldErrors.expirationDate}
                                 />
                             </div>
                         </div>
                         <div className="section"/>
                         {anonymous &&
                          <span>
                                <div className="row">
                                    <div className="col-12">
                                        Please copy to following URL:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 url">
                                        {assessmentsUrl.Url}{urlKey}
                                    </div>
                                    <div className="col-1 icon">
                                        <CopyOutlined
                                                onClick={() => { navigator.clipboard.writeText(assessmentsUrl.Url + urlKey) }}
                                                title="Copy Assessment URL"/>
                                    </div>
                                </div>
                            </span>}
                         {!anonymous &&
                          <span>
                                <div className="row">
                                    <div className="col-4">
                                        <FormControlLabel
                                                classes={{root: 'FormControlLabel'}}
                                                label="First line is header"
                                                control={
                                                    <Checkbox
                                                            classes={{root: "checkbox", checked: "checked"}}
                                                            checked={firstLine}
                                                            onChange={() => setFirstLine(!firstLine)}
                                                            // id={templateAnswer.id.toString()}
                                                    />
                                                }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={`col recipients ${fieldErrors.recipient ? "error" : ""}`}>
                                        {recipients.map((r) => {
                                            return <div key={r.name} className="recipient">
                                                <div className="recipientName" title={r.urlKey}>{r.name} ({r.email})
                                                </div>
                                                <div className="removeRecipient" id={r.name}
                                                     onClick={() => setToBeDeleted(r.name)}>
                                                    <CloseCircleOutlined title="Remove Recipient"/>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </span>
                         }
                         <input
                                 type="file"
                                 style={{display: "none"}}
                                 onChange={(e) => setFiles(e.target.files)}
                                 onClick={(e) => { e.target.value = null }}
                                 ref={inputRef}
                         />
                     </>
                    }
                </DialogContent>
                <div className="editorFooter">
                    <DialogActions>
                        {shouldShowMailEditor ?
                         <div style={{display: 'flex', width: '100%'}}>
                             {!isEqual(mailTemplate.id, 0) && (
                                     <div style={{flexBasis: '50%'}}>
                                         <Button
                                                 className="deleteButton"
                                                 variant="contained"
                                                 onClick={deleteEmailTemplateHandler}>
                                             Delete
                                         </Button>
                                     </div>)}
                             <div style={{flexBasis: isEqual(mailTemplate.id, 0) ? '100%' : '50%', textAlign: 'end'}}>
                                 <Button
                                         style={{margin: '0 5px'}}
                                         className="editorButton"
                                         variant="contained"
                                         onClick={() => setShouldShowMailEditor(false)}>
                                     Cancel
                                 </Button>
                                 <Button
                                         style={{margin: '0 5px'}}
                                         className="editorButton"
                                         variant="contained"
                                         onClick={() => setShouldSaveTemplate(true)}>
                                     Save Changes
                                 </Button>
                             </div>
                         </div> :
                         <>
                             {!anonymous ?
                              <>
                                  {mailTemplate && mailTemplate.id > 0 &&
                                   <Button className="editorButton" variant="contained"
                                           onClick={() => {
                                               setShouldShowMailEditor(true);
                                           }}>Edit Mail Template</Button>
                                  }

                                  <Button className="editorButton" variant="contained"
                                          onClick={selectRecipientFile}>Load Recipients</Button>
                                  <Button className="editorButton" variant="contained"
                                          onClick={() => {
                                              clear();
                                          }}>Clear</Button>
                              </>
                                         : null}
                             <Button className="editorButton" variant="contained"
                                     onClick={() => props.onClose()}>Cancel</Button>
                             <Button className="editorButton" variant="contained"
                                     onClick={() => setShouldAssign(true)}>Assign</Button>

                         </>}

                    </DialogActions>
                </div>
            </Dialog>

    );
}

export default withUser(AssessmentBatchAssign);